import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Login({ setAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Incorrect username or password');
        } else {
          throw new Error('Network response was not ok');
        }
      }

      // Устанавливаем состояние аутентификации при успешном входе
      setAuthenticated(true);
      
      // Переход на страницу, с которой пришли до логина
      navigate(from);
    } catch (error) {
      setError(error.message);
      console.error('There was an error logging in!', error);
    }
  };

  return (
    <div className="loginContainer">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default Login;