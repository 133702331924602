import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/coffees"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Сортируем посты: сначала с датой (от самой недавней до самой поздней), затем без даты
        const postsWithDate = data
          .filter((post) => post.date)
          .sort((a, b) => new Date(b.date * 1000) - new Date(a.date * 1000));

        const postsWithoutDate = data.filter((post) => !post.date);

        setPosts([...postsWithDate, ...postsWithoutDate]);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Функция для преобразования Unix timestamp в формат даты, месяца и года с двумя цифрами
  const formatUnixTimestamp = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0"); // Добавляем 0, если день состоит из одной цифры
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Добавляем 0, если месяц состоит из одной цифры
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="postsContainer">
      {posts.map((post) => (
        <div key={post.id} className="post">
          <div className="post-header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="post-title">{post.name}</h2>
              <div className="tooltip">
                <BsInfoCircle className="info" />
                <div className="tooltiptext">
                  Цена: {post.price}₽<br />
                  Объём: {post.volume}
                  <br />
                  Дата: {post.date ? formatUnixTimestamp(post.date) : "Дата выясняется"}
                  <br />
                </div>
              </div>
            </div>
            <p className="post-cafe">{post.cafe.name}</p>
          </div>
          <p className="post-description">{post.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Home;