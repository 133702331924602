import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UpdateCoffee.css'; // Подключаем файл стилей для компонента UpdateCoffee

function UpdateCoffee() {
  const [cafes, setCafes] = useState([]);
  const [selectedCafe, setSelectedCafe] = useState('');
  const [coffees, setCoffees] = useState([]);
  const [selectedCoffee, setSelectedCoffee] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [typeCoffee, setTypeCoffee] = useState('');
  const [volume, setVolume] = useState('');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 16));
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const coffeeTypes = [
    'Эспрессо', 'Американо', 'Капучино', 'Латте', 'Раф', 'Флэт Уайт',
    'Макиато', 'Мокка', 'Колд Брю', 'Нитро', 'Другое', 'Не кофе'
  ];

  // Получаем список всех кофейн
  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cafes/names`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(username + ':' + password),
          },
        });

        if (!response.ok) {
          throw new Error('Не удалось получить список кофейн');
        }

        const data = await response.json();
        setCafes(data);
      } catch (error) {
        setError('Ошибка при загрузке кофейн');
        console.error(error);
      }
    };

    fetchCafes();
  }, [username, password]);

  // Получаем список кофе для выбранной кофейни
  const handleCafeSelect = async (e) => {
    const cafeId = e.target.value;
    setSelectedCafe(cafeId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cafes/${cafeId}/coffees`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password),
        },
      });

      if (!response.ok) {
        throw new Error('Не удалось получить список кофе');
      }

      const data = await response.json();
      setCoffees(data);
    } catch (error) {
      setError('Ошибка при загрузке кофе');
      console.error(error);
    }
  };

  // Получаем данные о выбранном кофе
  const handleCoffeeSelect = async (e) => {
    const coffeeId = e.target.value;
    setSelectedCoffee(coffeeId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees/${coffeeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password),
        },
      });

      if (!response.ok) {
        throw new Error('Не удалось получить данные о кофе');
      }

      const data = await response.json();
      setName(data.name);
      setDescription(data.description);
      setPrice(data.price);
      setTypeCoffee(data.type_coffee);
      setVolume(data.volume);
      setDate(data.date ? new Date(data.date * 1000).toISOString().slice(0, 16) : '');
    } catch (error) {
      setError('Ошибка при загрузке данных о кофе');
      console.error(error);
    }
  };

  // Отправляем обновленные данные о кофе
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        name,
        description,
        price,
        type_coffee: typeCoffee,
        volume,
        cafe_id: selectedCafe,
        date: Math.floor(new Date(date).getTime() / 1000), // Преобразуем в UNIX timestamp
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees/${selectedCoffee}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password),
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Не удалось обновить данные о кофе');
      }

      const data = await response.json();
      console.log('Кофе обновлено:', data);

      navigate('/'); // Перенаправление на главную страницу после успешного обновления
    } catch (error) {
      setError('Ошибка при обновлении кофе');
      console.error('There was an error updating the coffee!', error);
    }
  };

  return (
    <div className="updateCoffeeContainer">
      <h2 className="formTitle">Обновить кофе</h2>
      {error && <p className="formError">{error}</p>}
      {!selectedCoffee ? (
        <>
          <div className="formGroup">
            <label className="formLabel">Выберите кофейню:</label>
            <select onChange={handleCafeSelect} defaultValue="" className="formSelect">
              <option value="" disabled>Выберите кофейню</option>
              {cafes.map((cafe) => (
                <option key={cafe.id} value={cafe.id}>
                  {cafe.name}
                </option>
              ))}
            </select>
          </div>

          {selectedCafe && (
            <div className="formGroup">
              <label className="formLabel">Выберите кофе:</label>
              <select onChange={handleCoffeeSelect} defaultValue="" className="formSelect">
                <option value="" disabled>Выберите кофе</option>
                {coffees.map((coffee) => (
                  <option key={coffee.id} value={coffee.id}>
                    {coffee.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      ) : (
        <form onSubmit={handleSubmit} className="updateCoffeeForm">
          <div className="formGroup">
            <label className="formLabel">Название:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Описание:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="formTextarea"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Цена:</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Тип кофе:</label>
            <select value={typeCoffee} onChange={(e) => setTypeCoffee(e.target.value)} className="formSelect">
              <option value="">Выберите тип</option>
              {coffeeTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="formGroup">
            <label className="formLabel">Объем (мл):</label>
            <input
              type="number"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Дата и время:</label>
            <input
              type="datetime-local"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Логин:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Пароль:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="formInput"
            />
          </div>
          <button type="submit" className="formButton">Update Coffee</button>
        </form>
      )}
    </div>
  );
}

export default UpdateCoffee;
