import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import AddPost from './AddPost';
import Login from './Login';
import Home from './Home';
import UpdateCoffee from "./UpdateCoffee";

const RequireAuth = ({ children, authenticated }) => {
  const location = useLocation();

  if (!authenticated) {
    // Если не аутентифицирован, перенаправляем на страницу логина и сохраняем текущий путь
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <Router>
      <div className="App">
        <div className="main">
          <header className="App-header">
            <h1>Кофмар</h1>
          </header>
          <hr />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
            <Route 
              path="/add-post" 
              element={
                <RequireAuth authenticated={authenticated}>
                  <AddPost />
                </RequireAuth>
              } 
            />
            <Route 
              path="/update-post" 
              element={
                <RequireAuth authenticated={authenticated}>
                  <UpdateCoffee />
                </RequireAuth>
              } 
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
